
import { DownOutlined } from '@ant-design/icons';
import { BargeRequest, BargeService, GetAllBargeDataDto, YESOrNoRadioEnum } from '@exportx/shared-models-and-services';
import { getFilesData } from '@exportx/ui-utils';
import { Button, Card, Col, Collapse, Dropdown, Form, Menu, MenuProps, Row, Space } from 'antd';
import { useEffect, useState } from 'react';
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { AlertMessages, isPermissionExist, useAuthState } from '../../../../../common';
import { BargeDetails } from '../barge-details';
import BargePreview from '../barge-preview';
import { BargeTimings } from '../barge-timings';
import Others from '../others';
import SurveyorDetails from '../surveyor-details';
import BargeSurveyorDetailsView from './barge-surveyor-details-view';
import { SaveAndNextButton } from '../../../../contracts/creation-forms';




const { Panel } = Collapse;
export interface BargeProps {
  Action?: string
}

export const BargeCreation = (props: BargeProps) => {
  const { formatMessage: fm } = useIntl();
  const [defaultOpenKey, setDefaultOpenKey] = useState(['1']);
  const [goToButtonKeys, setGoToButtonKeys] = useState([]);
  const [bargeOrderData, setBargeOrderData] = useState<any>({
    bargeDetails: {},
    bargeTiming: {},
    bargeSurveyor: {},
    bargeOthers: {},
    filesData: [],
  }
  );
  const [bargeDetailsFormRef] = Form.useForm();
  const [bargeTimingFormRef] = Form.useForm();
  const [bargeSurveyorFormRef] = Form.useForm();
  const [bargeOthersFormRef] = Form.useForm();
  const { state }: any = useLocation();
  const [pcQualityData, setPcQualityData] = useState<any>();
  const { bargeId } = state ? state : { bargeId: null };
  const { authContext } = useAuthState();
  const [getAllBarge, setGetAllBarge] = useState<GetAllBargeDataDto>();
  const [bargeIdSelection, setBargeIdSelection] = useState<any>();
  const [surveyorDropDownData, setSurveyorDropDownData] = useState<any[]>([]);
  const navigate = useNavigate();
  const [pcTypeId, setPcTypeId] = useState<any>();
  const [value, setValue] = useState<YESOrNoRadioEnum>(YESOrNoRadioEnum.NO);




  if (bargeId) {
    bargeDetailsFormRef.setFieldValue('bargePlannedQuantityInMt', bargeId?.bargePlannedQuantityInMt)
    bargeOthersFormRef.setFieldValue('chemicalSpray', bargeId?.chemicalSpray)
  };

  useEffect(()=>{
    let permission = false;
      if(bargeId){
        permission = isPermissionExist([264])
      } else {
        permission = isPermissionExist([262])
      }
      if(!permission) navigate('/')
  },[bargeId])

  useEffect(() => {
    if (bargeId) {
      getBargePreviewData(bargeId);
    }
  }, [state]);




  const getBargePreviewData = (bargeId: number) => {
    const req = new BargeRequest(bargeId);
    service.getBargePreviewData(req).then(res => {
      if (res.status) {
        setGetAllBarge(res.data);
        setPcQualityData({ pcQualities: res.data.pcQualities, pcPurchaseType: res.data.pcPurchaseType });
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => {
      console.log(err.message)
    })
  }

  const service = new BargeService();
  let pathToreDirect = '/barge-data';

  useEffect(() => {
    let limit = Number(defaultOpenKey[0]);
    let i: number, goto: number[] = [];
    for (i = 1; i < limit; i++) {
      goto.push(i);
    }
    setGoToButtonKeys(goto);
  }, [defaultOpenKey])

  const bargeDetailsFormHandler = () => {
    bargeDetailsFormRef.validateFields().then(values => {
      if (values.purchaseContract) {
        if(Array.isArray(pcQualityData) && pcQualityData.length) {
          values.pcQualities = pcQualityData?.[0]?.qualityId;
          values.pcPurchaseType = pcTypeId;
          values.purchasingTerm = pcQualityData?.[0]?.purchasingTerm;
          values.quality = pcQualityData?.[0]?.quality;
          values.purchaseType = pcQualityData?.[0]?.purchaseTypeData?.find((e)=> e.typeId == pcTypeId)?.purchaseType
        } else {
          values.pcQualities = getAllBarge?.pcQualities;
          values.pcPurchaseType = getAllBarge?.pcPurchaseType;
          values.purchasingTerm = getAllBarge?.purchaseTerm
          values.quality = getAllBarge?.quality
          values.purchaseType = getAllBarge?.purchaseType
        }
      }
      setBargeOrderData((prevState) => {
        return {
          ...prevState, bargeDetails: values
        }
      });
      setDefaultOpenKey(['2']);
    }).catch(errorInfo => {
      console.log(errorInfo, 'info')
      errorInfo?.errorFields?.map(item => console.log(item?.name[0], ' is a mandatory field'));
    })
  }

  const bargeTimingFormHandler = () => {
    bargeTimingFormRef.validateFields().then(values => {
      setBargeOrderData((prevState) => { return { ...prevState, bargeTiming: values } });
      setDefaultOpenKey(['3']);

    }).catch(errorInfo => {
      errorInfo?.errorFields?.map(item => console.log(item.name[0], ' is a mandatory field'));
    })
  }

  const bargeSurveyorFormHandler = () => {
    bargeSurveyorFormRef.validateFields().then(values => {
      setBargeOrderData((prevState) => { return { ...prevState, bargeSurveyor: values } });
      setDefaultOpenKey(['4']);

    }).catch(errorInfo => {
      errorInfo.errorFields.map(item => console.log(item.name[0], ' is a mandatory field'));
    })
  }

  const bargeOthersFormHandler = () => {
    bargeOthersFormRef.validateFields().then(values => {
      getFilesData(values.filesData).then(filesData => {
        setBargeOrderData((prevState) => { return { ...prevState, bargeOthers: values, filesData: filesData } });
        setDefaultOpenKey(['5']);
      });
    }).catch(errorInfo => {
      errorInfo.errorFields.map(item => console.log(item.name[0], ' is a mandatory field'));
    })
  }

  const saveBarge = () => {
    let some: any = bargeOrderData;
    let data = { ...some.bargeDetails, ...some.bargeTiming, ...some.bargeOthers, ...some.bargeSurveyor, bargingAgreementPriceListId: bargeIdSelection, filesData: some.filesData, userName: authContext.user.userName, plantCode: authContext.defaultPlant, chemicalSpray: value };
    service.createBarge(data).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        setTimeout(() => {
          navigate(pathToreDirect);
        }, 1000);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    })
  }

  const goToOnClickHandler: MenuProps['onClick'] = ({ key }) => {
    setDefaultOpenKey([`${key}`]);
  };

  const menu = (
    <Menu
      onClick={goToOnClickHandler}
      items={
        goToButtonKeys.map(item => {
          return {
            label: `${item}`,
            key: `${item}`,
          }
        })}
    />
  );
  return (
    <Collapse activeKey={defaultOpenKey} >
      <Panel header="Barge Details" key='1' >
        <BargeDetails setBargeOrderData={setBargeOrderData} setBargeIdSelection={setBargeIdSelection} formRef={bargeDetailsFormRef} initialValues={getAllBarge} layoutType='vertical' setPcQualitiesData={setPcQualityData} setPcTypeId={setPcTypeId}
        Action={props?.Action}
        >
          <Row justify="end">
            <Col>
              <SaveAndNextButton goto={goToButtonKeys} goToOnClickHandler={goToOnClickHandler} saveButtonHandler={bargeDetailsFormHandler} />
              {/* {
                <Space>
                  <Dropdown.Button
                    icon={<DownOutlined />}
                    overlay={menu}
                  >
                    {fm({ id: "common.goTo", defaultMessage: "Go To" })}
                  </Dropdown.Button>
                  <Button onClick={bargeDetailsFormHandler} type="primary">
                    Save and Next
                  </Button>
                </Space>
              } */}
            </Col>
          </Row>
        </BargeDetails>

      </Panel>
      <Panel header="Barge Timings" key='2'>
        <BargeTimings formRef={bargeTimingFormRef} initialValues={getAllBarge} layoutType='vertical'>
          <Row justify="end">
            <Col>
              <SaveAndNextButton goto={goToButtonKeys} goToOnClickHandler={goToOnClickHandler} saveButtonHandler={bargeTimingFormHandler} />
              {/* {
                <Space>
                  <Dropdown.Button
                    icon={<DownOutlined />}
                    overlay={menu}
                  >
                    {fm({ id: "common.goTo", defaultMessage: "Go To" })}
                  </Dropdown.Button>
                  <Button onClick={bargeTimingFormHandler} type="primary">
                    Save and Next
                  </Button>
                </Space>
              } */}
            </Col>
          </Row>
        </BargeTimings>

      </Panel>
      <Panel header="Surveyor Details" key="3">
        <SurveyorDetails formRef={bargeSurveyorFormRef} setSurveyorDropData={setSurveyorDropDownData} initialValues={getAllBarge} layoutType='vertical' >
          <Row justify="end">
            <Col>
              <SaveAndNextButton goto={goToButtonKeys} goToOnClickHandler={goToOnClickHandler} saveButtonHandler={bargeSurveyorFormHandler} />
              {/* {
                <Space>
                  <Dropdown.Button
                    icon={<DownOutlined />}
                    overlay={menu}
                  >
                    {fm({ id: "common.goTo", defaultMessage: "Go To" })}
                  </Dropdown.Button>
                  <Button onClick={bargeSurveyorFormHandler} type="primary">
                    Save and Next
                  </Button>
                </Space>
              } */}
            </Col>
          </Row>
        </SurveyorDetails>
      </Panel>
      <Panel header="Others" key="4">
        <Others
          formRef={bargeOthersFormRef}
          initialValues={getAllBarge}
          layoutType='vertical'
          value={value}
          setValue={setValue}
        >
          <Row justify="end">
            <Col>
              <SaveAndNextButton goto={goToButtonKeys} goToOnClickHandler={goToOnClickHandler} saveButtonHandler={bargeOthersFormHandler} />
              {/* {
                <Space>
                  <Dropdown.Button
                    icon={<DownOutlined />}
                    overlay={menu}
                  >
                    {fm({ id: "common.goTo", defaultMessage: "Go To" })}
                  </Dropdown.Button>
                  <Button onClick={bargeOthersFormHandler} type="primary">
                    Save and Next
                  </Button>
                </Space>
              } */}
            </Col>
          </Row>
        </Others>
      </Panel>
      <Panel header="Preview and Submit" key="5">
        <>
          <BargePreview getAllBarge={bargeOrderData} />
          <Card title={'Surveyor Details'} headStyle={{ backgroundColor: '#e6e0e0', color: '#000', border: 0 }} bodyStyle={{ padding: '0px', paddingTop: '24px' }}>
            <BargeSurveyorDetailsView surveyorDropDownData={surveyorDropDownData} bargeSurveyor={bargeOrderData?.bargeSurveyor} /></Card>
          <Row justify="end">
            <Col>
              <br />
              <br />
              <Row justify="end">
                <SaveAndNextButton goto={goToButtonKeys} goToOnClickHandler={goToOnClickHandler} saveButtonHandler={saveBarge} />
              </Row>
              {/* {
                <Row justify="end">
                  <Space>
                    <Dropdown.Button
                      icon={<DownOutlined />}
                      overlay={menu}
                    >
                      {fm({ id: "common.goTo", defaultMessage: "Go To" })}
                    </Dropdown.Button>
                    <Button onClick={saveBarge} type="primary">
                      Save
                    </Button>
                  </Space>
                </Row>
              } */}
            </Col>
          </Row>

          {/* </PreviewComponent> */}
        </>
      </Panel>
    </Collapse>

  )
}
export default BargeCreation;