import { getLocalFormat, SequenceUtils } from '@exportx/ui-utils';
import Table, { ColumnsType } from 'antd/es/table';
import moment from 'moment';


interface BargeIProps {
    bargeGridData: any;
    rowSelection: {
        columnTitle: JSX.Element;
        onChange: (selectedRowKeys: string[], selectedRows: any[]) => void;
        getCheckboxProps: (record: any) => {
            disabled: boolean;
        } | {
            disabled?: undefined;
        };
        selectedRowKeys: any[];
    }

}


const CommercialCostingsBargeSelection = (props: BargeIProps) => {

    const { bargeGridData, rowSelection } = props;

    const columns: any = [
        {
            title: 'SALES CONTRACT',
            dataIndex: 'contractNo',
        },
        {
            title: 'Barge Id',
            dataIndex: 'bargeId',
            // width: 160,
            fixed: 'left',

            render: (text, record) => {
                if(record?.bgUId)  {
                    
                const link = `/#/barge-detail-view?barge_id=${record.bgUId}`;
                return <>
                    <a href={link} className="link-primary" >
                        {SequenceUtils.formatNumberToSpecificLength(record.bargeId)}
                    </a></>
                } else return <span>NA</span>
            }
        },
        {
            title: 'BARGE NOMINATION',
            dataIndex: 'bargeNomination',
        },
        {
            title: 'JETTY',
            dataIndex: 'loadingJetty',
        },
        {
            title: 'LOADING DATE',
            dataIndex: 'loadingDate',
            render: (_, value) => {
                return <>
                    {moment(value).format('DD-MM-YYYY')}
                </>
            }
        },
        {
            title: 'MV NAME',
            dataIndex: 'businessNo',
        },
        {
            title: 'QUALITY',
            dataIndex: 'quality',
        },
        {
            title: 'SALES TYPE',
            dataIndex: 'incoterm',
        },
        {
            title: 'QUANTITY',
            dataIndex: 'quantityInMt',
            width: '80'
        },
        {
            title: 'PRICE',
            dataIndex: 'coalBasePrice',
            width: '80',
            render: (text, rowData) => {
                return (
                    <span>
                        {`${getLocalFormat(Number(rowData?.coalBasePrice), rowData?.baseCurrency)}`}
                    </span>
                )
            }
        },
        {
            title: 'FREIGHT PMT',
            dataIndex: 'freightPricePmt',
            width: '80',
            render: (text, rowData) => {
                return (
                    <span>
                        {`${getLocalFormat(Number(rowData?.freightPricePmt), rowData?.baseCurrency)}`}
                    </span>
                )
            }
        }



    ];

    return (
        <>
            <Table
                className='contracts'
                columns={columns}
                dataSource={bargeGridData}
                rowSelection={{ ...rowSelection }}
                rowKey={record => record.bgUId}
                pagination={false}
            // bordered={true}
            >

            </Table >


        </>
    )
}

export default CommercialCostingsBargeSelection