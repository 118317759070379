import { BargeDetailsDto, BargeRequest, BargeService, BusinessNumberService, GetAllBargeDataDto, getBargeStatusDataDto, PriceTypeEnum } from "@exportx/shared-models-and-services";
import { SequenceUtils, getFilesData } from "@exportx/ui-utils";
import { Affix, Button, Card, Col, Descriptions, Form, Layout, Row, Space, Table, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import Link from 'antd/es/typography/Link';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { AlertMessages, isPermissionExist, useAuthState } from "../../../../common";
import { FileUploadForm } from "../../../../common/file-upload-form/FileUploadForm";
import ContractQualityResults from "./contracts-quality-results";
import queryString from 'query-string';


export const BargeDetailView = () => {
    const location = useLocation();
    console.log(queryString.parse(location.search)?.barge_id);

    // const search = window.location.search;
    // const params = new URLSearchParams(search);
    const paramBargeId = queryString.parse(location.search)?.barge_id;
    const { authContext } = useAuthState();
    const { formatMessage: fm } = useIntl();
    const [filesFormRef] = Form.useForm();
    const printRef = React.useRef();
    const { state }: any = useLocation();
    const navigate = useNavigate();
    let { bargeId } = paramBargeId != null ? { bargeId: paramBargeId } : state ? state : { bargeId: null };
    // let { bargeId } = state ? state : { bargeId: null };
    const [previewData] = useState<BargeDetailsDto>(null);
    const [bargeCreation] = useState<BargeDetailsDto>(null);
    const [getAllBarge, setGetAllBarge] = useState<GetAllBargeDataDto>()
    const service = new BargeService();
    const [bargeTimings, setBargeTimings] = useState<getBargeStatusDataDto[]>([])
    const bnService = new BusinessNumberService();
    let pathToreDirect = '/bn-detail-view'


    let pathToreDirec = '/barge-detail-view'

    const redirectToFull = (bargeId: string) => {
        navigate(pathToreDirec, { state: { bargeId } });
    }

    let pathToreDirectPc = '/po-detail-view'

    const redirectToFullViewPc = (contractId: string) => {
        navigate(pathToreDirectPc, { state: { contractId } });
    }

    const redirectToFullView = (businessNo: string) => {
        navigate(pathToreDirect, { state: { businessNo } });
    }
    console.log(bargeId, "barge")
    useEffect(() => {
        getBargePreviewData(bargeId);
        bargeTimingData(bargeId)
    }, [bargeId]);

    const bargeTimingData = (bargeId) => {
        const req = new BargeRequest(bargeId, authContext.defaultPlant)
        bnService.getBargeStatusData(req).then(res => {
            if (res.status) {
                setBargeTimings(res.data)
            }
        }).catch(err => console.log(err.message))
    }


    const getBargePreviewData = (bargeId: number) => {
        const req = new BargeRequest(bargeId);
        service.getBargePreviewData(req).then(res => {
            if (res.status) {
                setGetAllBarge(res.data)
            } else {
                AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        })
    }



    const handleDownloadPdf = async () => {
        const element = printRef.current;
        const canvas = await html2canvas(element);
        const data = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        const imgProperties = pdf.getImageProperties(data);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight =
            (imgProperties.height * pdfWidth) / imgProperties.width;
        pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('print.pdf');
    };

    const setFormData = (bargeId: number) => {
        const req = new BargeRequest(bargeId);
        service.getBargePreviewData(req).then(res => {
            if (res.status) {
                console.log(res.data);
                navigate('/barge-update', { state: { bargeId: getAllBarge.id } });
            } else {
                AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
            }
        }).catch(err => {
            AlertMessages.getErrorMessage(err.message);
        })
        // navigate('/barge-update', { state: previewData });
    }

    const fileUploadFormHandler = () => {
        filesFormRef.validateFields().then(fileValues => {
            getFilesData(fileValues.filesData).then(filesData => {
                service.addMoreFilesToBarge({
                    filesData: filesData,
                    updatedUser: authContext.user.userName,
                    bargeId: bargeId,
                    plantCode: authContext.defaultPlant
                }).then(res => {
                    if (res.status) {
                        getBargePreviewData(bargeId);
                        AlertMessages.getSuccessMessage(res.internalMessage);
                    } else {
                        AlertMessages.getErrorMessage(res.internalMessage);
                    };
                }).catch(err => {
                    AlertMessages.getErrorMessage(err.message);
                });
            });
        });
    };

    const bargeTimingColumns: ColumnsType<any> = [
        {
            title: 'Business No',
            dataIndex: 'businessNumber',
            render: (text, record) => <><a className="link-primary" onClick={() => { redirectToFullView(record?.businessNoId); }}>
                {record.businessNumber}
            </a></>
        },
        {
            title: 'Allocation',
            dataIndex: 'allocationId'
        },
        {
            title: 'Barge Status',
            dataIndex: 'bargeStatus'
        }, ,
        {
            title: 'Commence Discharging',
            dataIndex: 'commenceDischarge'
        }, ,
        {
            title: 'Completed Discharging',
            dataIndex: 'completionDischarge'
        }, ,
        {
            title: 'Time at Anchorage',
            dataIndex: ''
        },
    ]
    const link = `/#/po-detail-view?pc_id=${getAllBarge?.purchaseContract}`
    const links = `/#/detailCustomer?bp_id=${getAllBarge?.bargeVendor}`

    return (
        <>
            <Layout style={{ padding: 10, backgroundColor: 'white', border: 10, maxHeight: '90vh', overflowY: 'scroll' }}>
                <Row justify="end">
                    <Col><> <Button href='/barge-data#/barge-data' style={{ color: 'black' }}>Barge Grid</Button>
                        <Button style={{ marginLeft: 20 }} onClick={handleDownloadPdf}>Download Barge</Button>
                    </></Col></Row>
                <Card
                    title={<span >Barge Detail View  {bargeCreation?.['bargeNomination']} <Tag color="#55acee">
                        {previewData?.['status']}
                    </Tag></span>}
                    style={{ textAlign: 'center' }}
                    headStyle={{ backgroundColor: '#2196f3', color: '#fff' }}
                    ref={printRef}
                // extra={<>{getExtraComponent(previewData?.['status'])}</>}
                >
                    <br></br>
                    <Card title={'Barge Details'} headStyle={{ backgroundColor: '#e6e0e0', color: '#000', border: 0 }} bodyStyle={{ paddingBottom: '5px' }}>
                        <Descriptions
                            title=""
                            column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                        >
                            <Descriptions.Item label="Barge Nomination">
                                {getAllBarge?.bargeNomination}
                            </Descriptions.Item>
                            <Descriptions.Item label="Purchase Contract">
                                {/* {getAllBarge?.purchaseContractNo} */}
                                <span ><Link href={link}>{getAllBarge?.purchaseContractNo}</Link></span>
                            </Descriptions.Item>
                            <Descriptions.Item label="Purchase Contract Name">
                                {getAllBarge?.bargeVendors}
                            </Descriptions.Item>
                            <Descriptions.Item label="Purchase Type">
                                {getAllBarge?.purchaseType}
                            </Descriptions.Item>
                            <Descriptions.Item label="Purchase Terms">
                                {getAllBarge?.purchaseTerm}
                            </Descriptions.Item>

                            <Descriptions.Item label="Purchase Quality">
                                {getAllBarge?.quality}
                            </Descriptions.Item>


                            <Descriptions.Item label="Barge Vendor">

                                <span ><Link href={links}>{getAllBarge?.bargeVendorName}</Link></span>
                            </Descriptions.Item>
                            <Descriptions.Item label="Barging Type">
                                {getAllBarge?.bargingType}
                            </Descriptions.Item>
                            {getAllBarge?.bargingType === PriceTypeEnum.PER_MT ? 
                            
                            <Descriptions.Item label="Base Price Per MT">
                                { getAllBarge?.bargingBasePrice || 0} {getAllBarge?.bargingBasePriceCurrency}
                            </Descriptions.Item>
                        
                        :
                        
                            <Descriptions.Item label='Lumpsum Price'>
                                {getAllBarge?.lumpSumBasePrice || 0} {getAllBarge?.lumpSumBasePriceCurrency }
                            </Descriptions.Item>
                        }
                            {/* <Descriptions.Item label="Vat Applicability">
                                {getAllBarge?.vatApplicability}
                            </Descriptions.Item> */}
                            <Descriptions.Item label="Loading Jetty">
                                {getAllBarge?.loadingJetty}
                            </Descriptions.Item>
                            <Descriptions.Item label="Barge Quantity in MT">
                                {getAllBarge?.bargeQuantityInMt}
                            </Descriptions.Item>
                            <Descriptions.Item label="Barge Loaded Quantity in MT">
                                {getAllBarge?.loadedQuantityInMt}
                            </Descriptions.Item>
                            <Descriptions.Item label="Return Cargo Quantity In MT">
                                {getAllBarge?.returnCargoQuantityInMt}
                            </Descriptions.Item>

                            <Descriptions.Item label="Dead Weight">
                                {getAllBarge?.deadFreight}
                            </Descriptions.Item>
                            <Descriptions.Item label="Dead Weight Incurred">
                                {getAllBarge?.deadFreightIncurred}
                            </Descriptions.Item>
                            <Descriptions.Item label="Dead Weight Applicability">
                                {getAllBarge?.deadFreightApplicability}
                            </Descriptions.Item>
                            <Descriptions.Item label="TDS">
                                {getAllBarge?.pph5}
                            </Descriptions.Item>
                            <Descriptions.Item label="Barges Id">
                                <span onClick={() => redirectToFull(getAllBarge?.returnBarge)}><Link >
                                    {getAllBarge?.returnBargeId ? SequenceUtils.formatNumberToSpecificLength(getAllBarge?.returnBargeId?.toString()) : ''}</Link></span>
                            </Descriptions.Item>
                        </Descriptions>
                        <br></br>
                        <Descriptions
                            title=""
                            column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                        >

                            {/* <Descriptions.Item label="Dead Frieght">
                                {getAllBarge?.}
                            </Descriptions.Item> */}
                        </Descriptions>
                    </Card>
                    <br></br>
                    <Card title={'Barge Timing'} headStyle={{ backgroundColor: '#e6e0e0', color: '#000', border: 0 }} bodyStyle={{ padding: '0px', paddingTop: '24px' }}>
                        <Descriptions
                            title=""
                            column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                        >
                            <Descriptions.Item label="Loading Start Date">
                                {/* {moment(getAllBarge?.loadingDate).format('DD-MMM-YYYY HH:MM')} */}
                                {getAllBarge?.loadingDate ? moment(getAllBarge?.loadingDate).format('DD-MM-YYYY HH:mm') : ''}
                            </Descriptions.Item>
                            <Descriptions.Item label="Loading Completion Date">
                                {/* {moment(getAllBarge?.completionTime).format('DD-MMM-YYYY HH:MM')} */}
                                {getAllBarge?.completionTime ? moment(getAllBarge?.completionTime).format('DD-MM-YYYY HH:mm') : ''}
                            </Descriptions.Item>
                            <Descriptions.Item label="NOR Anchorage">
                                {/* {moment(getAllBarge?.norAnchorage).format('DD-MMM-YYYY  HH:MM')} */}
                                {getAllBarge?.norAnchorage ? moment(getAllBarge?.norAnchorage).format('DD-MM-YYYY HH:mm') : ''}
                            </Descriptions.Item>

                        </Descriptions>

                        <Table columns={bargeTimingColumns} dataSource={bargeTimings} pagination={false}>
                        </Table>
                    </Card>
                    <Card title={"Quality Results"}>
                        <ContractQualityResults bargeId={bargeId} bgId={getAllBarge?.bargeId} />
                    </Card>
                    <br></br>
                    <Card title={'Surveyor Details'} headStyle={{ backgroundColor: '#e6e0e0', color: '#000', border: 0 }} bodyStyle={{ padding: '0px', paddingTop: '24px' }}>
                        <table className='table table-sm table-bordered' >
                            <tr>
                                <th></th>
                                <th>Surveyor Name</th>
                                <th>Analysis Type</th>
                            </tr>
                            <tr>
                                <td>
                                    Supplier Surveyor
                                </td>
                                <td>
                                    {getAllBarge?.supplierSurveyorName}
                                </td>
                                <td>
                                    {getAllBarge?.supplierSurveyorAnalysisType}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Internal Surveyor
                                </td>
                                <td>
                                    {getAllBarge?.internalSurveyorName}
                                </td>
                                <td>
                                    {getAllBarge?.internalSurveyorAnalysisType}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Witness Surveyor
                                </td>
                                <td>
                                    {getAllBarge?.witnessSurveyorName}
                                </td>
                                <td>
                                    {getAllBarge?.jointSurveyorAnalysisType}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Add Surveyor 1
                                </td>

                                <td>
                                    {getAllBarge?.additionalAnalysisType1Name1}
                                </td>
                                <td>
                                    {getAllBarge?.additionalAnalysisType}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Add Surveyor 2
                                </td>
                                <td>
                                    {getAllBarge?.additionalSurveyorName1}
                                </td>
                                <td>
                                    {getAllBarge?.additionalAnalysisType1}
                                </td>
                            </tr>
                        </table>
                    </Card>

                    <Card title={'Other Details'} headStyle={{ backgroundColor: '#e6e0e0', color: '#000', border: 0 }} bodyStyle={{ padding: '0px', paddingTop: '24px' }}>
                        <Descriptions
                            title=""
                            column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                        >
                            <Descriptions.Item label="Chemical Spray">
                                {getAllBarge?.chemicalSpray}
                                {/* {getAllBarge?.chemicalSpray ? 'Yes' : 'No'} */}
                            </Descriptions.Item>
                            <Descriptions.Item label="Chemical Spray Value">
                                {getAllBarge?.chemicalSprayPrice}
                            </Descriptions.Item>
                            <Descriptions.Item label="Remarks">
                                {getAllBarge?.remarks}
                            </Descriptions.Item>
                        </Descriptions>
                        {isPermissionExist([269]) && (
                        <FileUploadForm key={Date.now()} maxCount={3} layoutType='vertical' formRef={filesFormRef} initialValues={getAllBarge?.filesData ? getAllBarge?.filesData : []} >
                            <Row justify="end">
                                <Col>
                                    {
                                        <Space>
                                            <Button onClick={fileUploadFormHandler} type="primary">
                                                Save and Next
                                            </Button>
                                        </Space>
                                    }</Col>
                            </Row>
                        </FileUploadForm>)}

                    </Card><br></br>
                </Card><br></br>

                <Affix offsetBottom={0}>
                    <Card bodyStyle={{ padding: '6px 12px' }}>
                        <Row justify='space-between'>
                            <Col><Button onClick={() => { navigate(-1) }}>Back</Button></Col>
                            <Col>{isPermissionExist([264]) && (<Button onClick={() => { setFormData(bargeId) }}>Edit</Button>)}</Col>
                        </Row>
                    </Card>
                </Affix>
            </Layout>
        </>
    )
}
export default BargeDetailView;