

import { CheckCircleOutlined, EditFilled, FileExcelFilled, PlusSquareOutlined } from '@ant-design/icons';
import { AdvanceAll, AdvanceEnum, AdvancePreview, AdvanceReceiversService, ContractStatusEnum, ContractTypeDto, VendorAdvService, } from '@exportx/shared-models-and-services';
import { Button, Card, Input, Table, Tooltip } from 'antd';
import { Excel } from 'antd-table-saveas-excel';
import { IExcelColumn } from 'antd-table-saveas-excel/app';
import Link from 'antd/lib/typography/Link';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { AlertMessages, isPermissionExist, StatusTag, useAuthState } from '../../../../common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPersonCircleCheck } from '@fortawesome/free-solid-svg-icons';

interface IAdvanceGridProps {
  advanceCategory: AdvanceEnum
}


const AdvanceReceivablesGrid = (props: IAdvanceGridProps) => {

  const navigate = useNavigate();
  const { formatMessage: fm } = useIntl();
  const { authContext } = useAuthState()

  const { state }: any = useLocation();
  const { returnContractType, record, } = state ? state : { returnContractType: null, record: null }
  const { id } = record ? record : { id: null }
  const [gridData, setGridData] = useState<any>();
  const service = new AdvanceReceiversService();
  let pathToreDirect = '/advance-receivables-detailed-view'


  const [searchData, setSearchData] = useState("")

  useEffect(() => {
    if(!isPermissionExist([234])) navigate('/')
    if (props.advanceCategory) {

      getReceivablesGridData();
    }
  }, [props.advanceCategory])

  useEffect(() => {
    if (returnContractType) {
      getReceivablesGridData();

    }
  }, [returnContractType])



  const getReceivablesGridData = () => {
    const req = new ContractTypeDto();
    req.contractType = record ? record : props.advanceCategory;
    req.plantCode = authContext.defaultPlant
    service.getReceivablesGridData(req).then(res => {
      if (res.status) {
        setGridData(res.data);
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  };


  const advanceReceivableSendForApproval = (id: string) => {
    const req = new AdvancePreview(id, authContext.defaultPlant, authContext.user.userName,);
    service.advanceReceivableSendForApproval(req).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
        getReceivablesGridData()
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })

  }
  const advanceReceivableApproveOrReject = (id: string) => {
    const req = new AdvancePreview(id, authContext.defaultPlant, authContext.user.userName, ContractStatusEnum.ACTIVE);
    service.advanceReceivableApproveOrReject(req).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
        getReceivablesGridData()
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })

  }





  const view = ((vendorAdvData: AdvanceAll, isUpdate: boolean = false) => {
    // let isNewRecord = true;
    // if (vendorAdvData) {
    //   isNewRecord = false;
    // }
    let pathToreDirect = '/advance-receivables-create';
    navigate(pathToreDirect);
  }
  );

  const tableColumns: any = [

    {
      title: 'Vendor Name',
      dataIndex: 'vendorName',
      width: 130,
      align: 'left',
      sorter: (a, b) => a.vendorName.localeCompare(b.vendorName),
      sortDirections: ['descend', 'ascend'],
    },

    {
      title: 'Contract No',
      dataIndex: 'contractNo',
      width: 130,
      align: 'left',
      sorter: (a, b) => a.contractNo != null ? a.contractNo.localeCompare(b.contractNo) : "Have Null Values",
      sortDirections: ['descend', 'ascend'],
      filteredValue: [String(searchData).toLowerCase()],
      onFilter: (value, record) => {
        return (
          String(record.contractNo)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.contractDate)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.vendorName)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.amount)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.negotiationCurrency)
            .toLowerCase()
            .includes(value.toLocaleString()) ||
          String(record.saleContractNo)
            .toLowerCase()
            .includes(value.toLocaleString())
        )
      },
      render: (value, record) => {
        const link = `/#${pathToreDirect}?id=${record.id}`
        return <> {
          <Link href={link} className="link-primary" >{record.contractNo}</Link>
        }
        </>
      }

    },


    {
      title: 'Sale Contract',
      dataIndex: 'saleContractNo',
      width: 125,
      align: 'left',
      render: (v, rec) => {
        return <>
          <Link href={`/#/so-detail-view?sc_id=${rec.scId}`} className="link-primary">{v}</Link>
        </>
      }
    },

    {
      title: 'Contract Date',
      dataIndex: 'contractDate',
      width: 125,
      align: 'left',
      render: (text, record) => { return record.contractDate !== undefined ? moment(record.contractDate).format('DD-MM-YYYY') : "" },
    },

    {
      title: 'Amount',
      dataIndex: 'amount',
      width: 130,
      align: 'left',
      sorter: (a, b) => a.amount.localeCompare(b.amount),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Currency',
      dataIndex: 'negotiationCurrency',
      width: 130,
      align: 'left',
      sorter: (a, b) => a.negotiationCurrency.localeCompare(b.negotiationCurrency),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 130,
      align: 'center',
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => <>
        <StatusTag status={record.status} />
      </>,
    },
    {
      title: 'Action',
      className: 'operations',
      width: 130,
      align: 'left',
      render: (value, record) => (<span>
        {(record.status !== ContractStatusEnum.CLOSED && record.status !== ContractStatusEnum.ACTIVE) ?
          <>
            <EditFilled type="edit"
              onClick={() => {
                editClickHandler(record.id)
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />  <span> | </span></> : null
        }
        {(record.status === ContractStatusEnum.DRAFT) ? <><Tooltip placement="top" title={fm({ id: "common.advanceReceivableSendForApproval", defaultMessage: 'Send For Approval' })}>
          <FontAwesomeIcon icon={faPersonCircleCheck} style={{ color: '#1890ff', fontSize: '14px' }}
            onClick={() => {
              advanceReceivableSendForApproval(record.id);
            }} className={`hover ${record.status !== ContractStatusEnum.DRAFT ? 'disabled-button' : ''}`} />
        </Tooltip><span> | </span></> : null}

        {(record.status === ContractStatusEnum.PENDING_APPROVAL && isPermissionExist([237])) ?
          <><Tooltip placement="top" title={fm({ id: "common.approve", defaultMessage: 'Approve' })}>
            <CheckCircleOutlined type='approve'
              onClick={() => {
                advanceReceivableApproveOrReject(record.id)
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
          </Tooltip></> : null
        }
        {(record.status === ContractStatusEnum.ACTIVE) ?
          <><Tooltip placement="top" title={fm({ id: "common.approve", defaultMessage: 'Addendum' })}>
            <PlusSquareOutlined type='approve'
              onClick={() => {
                // advanceReceivableApproveOrReject(record.id)
              }}
              style={{ color: '#1890ff', fontSize: '14px' }}
            />
          </Tooltip></> : null
        }
      </span>)
      ,
    }];


  const editClickHandler = (id: string,) => {
    const req = new AdvancePreview(id, authContext.defaultPlant);
    service.getAllAdvReceivablesData(req).then(res => {
      if (res.status) {
        navigate('/advance-receivables-update', { state: res.data });
      } else {
        AlertMessages.getErrorMessage(fm({ id: `mdm.errorCodes.${res.errorCode}`, defaultMessage: res.internalMessage }));
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    });
  };

  const handleExport = (e: any) => {
    e.preventDefault();

    // Formatting columns - to set default render

    const currentDate = new Date()
      .toISOString()
      .slice(0, 10)
      .split("-")
      .join("/");
    // Formatting columns - to set default render
    let cloneArr = tableColumns.slice(0);
    cloneArr.splice(0, 1);
    cloneArr.splice(-1);
    const exportingColumns: IExcelColumn[] = cloneArr.map((item) => {
      if (typeof item.title === 'string')
        return { title: item.title, dataIndex: item.dataIndex };
      else
        return { title: item.dataIndex.toLocaleUpperCase(), dataIndex: item.dataIndex };
    });

    const excel = new Excel();
    excel
      .addSheet("Sheet1")
    excel.addRow();
    excel.addColumns(exportingColumns)
      .addDataSource(gridData)
      .saveAs(`Advance-${currentDate}.xlsx`);
  };


  return (
    <div>
      <Card
        title={props.advanceCategory}
        style={{ overflow: 'auto' }}
        extra={<>{isPermissionExist([233]) && (
          <Button
            type='primary'
            onClick={() => { view(undefined, false); }}
          >
            {fm({ id: 'common.create', defaultMessage: 'Create' })}
          </Button>)}</>
        }
        bodyStyle={{ overflow: 'auto' }}>
        <Card>
          {isPermissionExist([239]) && (
          <Tooltip placement="topRight" title="Export As Excel">
            <Button
              style={{ float: "right" }}
              size='small'
              type="default"
              onClick={handleExport}
              icon={<FileExcelFilled />}
            >Export</Button>
          </Tooltip>)}
          <Input.Search placeholder="Search" allowClear onChange={(e) => { setSearchData(e.target.value) }} onSearch={(value) => { setSearchData(value) }} style={{ width: 200, float: "right" }} />

          <Table
            pagination={false}
            className='contracts' rowKey={record => record.Id} columns={tableColumns} dataSource={gridData} scroll={{ x: 500 }}
            size="large"
            bordered />
        </Card>
      </Card>


    </div>
  )

}

export default AdvanceReceivablesGrid


